import {httpApiV3, httpNewApi, useVzFetch} from '~/composables/useVzFetch'
import {IOrderFavor, IOrdersRequest, IOrderTab, OrderListCard} from '~/types/components/personal/order-list'
import {Meta} from '~/types/types';
import {IRequestError} from '~/types/api';
import {OrderStatus} from '~/types/store';

export function getOrderTabs() {
  return useVzFetch(
      `${httpNewApi}/personal/order-tab/get-list`,
      {method: 'post'}
  ) as Promise<IOrderTab[]>
}

export function getOrders(body: IOrdersRequest, signal: AbortSignal|undefined = undefined) {
  return useVzFetch(
      `${httpNewApi}/personal/order-list/get`,
      { method: 'post', body, signal }
  ) as Promise<{ data: IOrderTab[], meta: Meta } & { error?: IRequestError }>
}

export function getOrderFavorList() {
  return $fetch(
      `${httpNewApi}/order/favor/get-list`,
      { method: 'post' }
  ) as Promise<IOrderFavor[]>
}

export function getOrderStatuses() {
  return $fetch(
      `${httpNewApi}/order/state/get-list`,
      { method: 'post' }
  ) as Promise<OrderStatus[]>
}

export function addOrderTab(body: Pick<IOrderTab, 'name'|'object'>) {
  return useVzFetch(
      `${httpNewApi}/personal/order-tab/add-tab`,
      { method: 'post', body }
  ) as Promise<IOrderTab[] & { error?: IRequestError }>
}

export function sortOrderTabs(body: {list: IOrderTab[]}) {
  return useVzFetch(
      `${httpNewApi}/personal/order-tab/update-sort`,
      { method: 'post', body }
  ) as Promise<IOrderTab[]>
}

export function updateOrderTab(body: IOrderTab) {
  return useVzFetch(
      `${httpNewApi}/personal/order-tab/update-tab`,
      { method: 'post', body }
  ) as Promise<IOrderTab[]>
}

export function removeOrderTab(body: Pick<IOrderTab, 'tabId'>) {
  return useVzFetch(
      `${httpNewApi}/personal/order-tab/remove-tab`,
      { method: 'post', body }
  ) as Promise<IOrderTab[]>
}

export function getOrderListCard() {
  return useVzFetch<{
    card: OrderListCard[],
    setting: { value?: 'standard' | 'simplified', defaultValue: 'standard' | 'simplified' }
  }>(`${httpApiV3}/personal/orderList/card`)
}

export function updateOrderListCard({ card, setting }: { card: OrderListCard[], setting: 'standard' | 'simplified' }) {
  return useVzFetch<{
    card: OrderListCard[],
    setting: { value?: 'standard' | 'simplified', defaultValue: 'standard' | 'simplified' }
  }>(
      `${httpApiV3}/personal/orderList/card`,
      { method: 'post', body: { card, setting } }
  )
}
