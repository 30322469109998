import {IShippingSchema} from '~/types/order/order';
import {
  IFilterBadge,
  IOrderFavor,
  IOrderTab,
  OrderListCard,
  OrderTabName
} from '~/types/components/personal/order-list';
import { useDayjs } from '#dayjs'
import {
  addOrderTab,
  getOrderFavorList, getOrderListCard,
  getOrderStatuses,
  getOrderTabs, removeOrderTab,
  sortOrderTabs,
  updateOrderTab
} from '~/api/order-list';
import {getDraftFavorList} from '~/api/draft';
import {getOrderDocument} from '~/api/document';
import {IOrderDocumentRequest, IOrderSendBillRequest} from '~/types/api';
import {orderSendBillToEmail} from '~/api/order';
import {OrderStatus} from '~/types/store';
import {defineStore} from 'pinia';
import {useUserStore} from '~/stores/personal/user';
import {useLayoutStore} from '~/stores/personal/layout';

export const useOrderListStore = defineStore('orderList', () => {
  const { $hideDialog, $showToast } = useNuxtApp()
  const { isAuth } = toRefs(useUserStore())
  const { modalLoader } = toRefs(useLayoutStore())
  const dayjs = useDayjs()

  const statuses = ref<OrderStatus[]>([])
  const favors = ref<IOrderFavor[]>([])
  const draftFavors = ref<IOrderFavor[]>([])
  const tabs = ref<IOrderTab[]>([])
  const lastCurrentTab = ref({} as IOrderTab)
  const shippingDiagram = ref<IShippingSchema[]>([])
  const orderListCardSettings = ref<{ card: OrderListCard[], simple: boolean }>({ card: [], simple: false })

  const allTabs = computed(() => {
    return tabs.value.filter(tab => (!tab.locked) || tab.tabCode === 'filtered')
  })

  const activeTabs = computed(() => {
    return tabs.value.filter(tab => !tab.hidden || tab.default)
  })

  const defaultTab = computed(() => {
    return tabs.value.find(tab => tab.default) || {} as IOrderTab
  })

  const orderListCardSettingsView = computed(() => {
    return orderListCardSettings.value.card
        .filter(item => !item.hidden)
        .sort((a, b) => a.sort - b.sort)
        .map((item, index) => ({...item, sort: index}))
  })

  // FILTERED: Добавление в объект
  const badges = computed(() => {
    const res: IFilterBadge[] = []
    const filter = tabByCode('filtered')
    if (filter.object?.date?.start) {
      res.push({
        type: 'dateStart',
        value: filter.object?.date?.start,
        display: 'С ' + dayjs(filter.object?.date?.start).format('DD.MM.YYYY'),
        displayValue: dayjs(filter.object?.date?.start).format('DD.MM.YYYY')
      })
    }
    if (filter.object?.date?.end) {
      res.push({
        type: 'dateEnd',
        value: filter.object?.date?.end,
        display: 'До ' + dayjs(filter.object?.date?.end).format('DD.MM.YYYY'),
        displayValue: dayjs(filter.object?.date?.end).format('DD.MM.YYYY')
      })
    }

    if (filter.object?.location?.dispatch) {
      res.push({
        type: 'locationDispatch',
        value: filter.object?.location?.dispatch,
        display: 'Откуда: ' + filter.object?.location?.dispatchTitle,
        displayValue: filter.object?.location?.dispatchTitle
      })
    }
    if (filter.object?.location?.destination) {
      res.push({
        type: 'locationDestination',
        value: filter.object?.location?.destination,
        display: 'Куда: ' + filter.object?.location?.destinationTitle,
        displayValue: filter.object?.location?.destinationTitle
      })
    }

    if (filter.object?.customer?.dispatch) {
      res.push({
        type: 'customerDispatch',
        value: filter.object?.customer?.dispatch,
        display: 'От кого: ' + filter.object?.customer?.dispatchTitle,
        displayValue: filter.object?.customer?.dispatchTitle
      })
    }
    if (filter.object?.customer?.destination) {
      res.push({
        type: 'customerDestination',
        value: filter.object?.customer?.destination,
        display: 'Кому: ' + filter.object?.customer?.destinationTitle,
        displayValue: filter.object?.customer?.destinationTitle
      })
    }
    if (filter.object?.customer?.payer) {
      res.push({
        type: 'customerPayer',
        value: filter.object?.customer?.payer,
        display: 'Плательщик: ' + filter.object?.customer?.payerTitle,
        displayValue: filter.object?.customer?.payerTitle
      })
    }

    if (filter.object?.projectsRaw) {
      Object.entries(filter.object.projectsRaw).forEach(([project, value]) => {
        res.push({
          type: 'projects',
          value: value.guid,
          display: 'Проект: ' + project,
          displayValue: project
        })
      })
    }

    if (filter.object?.marketplacesRaw) {
      Object.entries(filter.object.marketplacesRaw).forEach(([market, value]) => {
        res.push({
          type: 'marketplaces',
          value: value.guid,
          display: 'Маркетплейс: ' + market,
          displayValue: market
        })
      })
    }

    if (filter.object?.state) {
      Object.entries(filter.object.state).forEach(([state, value]) => {
        const s = statusByCode(state)
        res.push({
          type: 'state',
          value: s.code,
          display: (value ? '' : 'Не ') + s.name,
          displayValue: (value ? '' : '-') + s.name
        })
      })
    }

    filter.object?.number?.forEach(
        n => res.push({ type: 'number', value: n, display: `№ ${n}`, displayValue: n })
    )

    return res
  })

  const visibleStatuses = computed(() => {
    return statuses.value.filter(s => !s.hidden)
  })

  function statusByCode(code: string | number, propertyName: keyof OrderStatus = 'code'): OrderStatus {
    return statuses.value.find(status => status[propertyName] === code) || {} as OrderStatus
  }

  function favorByCode(code: IOrderFavor['code']|string): IOrderFavor {
    return favors.value.find(favor => favor.code === code) || draftFavors.value.find(favor => favor.code === code) || {} as IOrderFavor
  }

  function tabByCode(code: OrderTabName): IOrderTab {
    return code
      ? tabs.value.find(tab => tab.tabCode === code || tab.tabId === +code) || {} as IOrderTab
      : {} as IOrderTab
  }

  function tabKey(tab: IOrderTab): OrderTabName {
    return tab.tabCode || tab.tabId
  }

  function indexByCode(code: OrderTabName): number {
    return code
      ? tabs.value.findIndex(tab => tab.tabCode === code || tab.tabId === +code)
      : -1
  }

  function setTabs(value: IOrderTab[]) {
    if (!value) {
      return
    }
    /** Залоченные табы оставляем без изменения. Нужно для результатов поиска */
    tabs.value.filter(t => t.locked)
        .forEach((t) => {
          value = value.map((newT) => {
            return (newT.tabCode && newT.tabCode === t.tabCode) ? t : newT
          })
        })

    tabs.value = value
  }

  function setTab({ tab, index, code }: {tab: IOrderTab|any, index?: number, code?: OrderTabName}): void {
    if (index === undefined && code) {
      index = tabs.value.findIndex(tab => tab.tabCode === code || tab.tabId === code)
    }
    if (index !== undefined && ~index) {
      tabs.value[index] = tab
      tabs.value = [...tabs.value]
    }
  }

  async function getStatuses(): Promise<void> {
    if (statuses.value.length) { // Закэшировано
      return
    }
    statuses.value = await getOrderStatuses()
  }

  async function getFavors(): Promise<void> {
    if (favors.value.length) { // Закэшировано
      return
    }
    favors.value = await getOrderFavorList()
  }

  async function getDraftFavors(): Promise<void> {
    if (draftFavors.value.length) { // Закэшировано
      return
    }
    draftFavors.value = await getDraftFavorList()
  }

  async function getOrderListCardSettings() {
    const res = await getOrderListCard()
    orderListCardSettings.value = {
      card: res.card,
      simple: (res.setting?.value || res.setting?.defaultValue) === 'simplified'
    }
  }

  async function getTabs(): Promise<void> {
    try {
      setTabs(await getOrderTabs())
    } catch (e: any) {
      if (e.response?._data?.message) {
        $showToast({
          title: 'Проблема с загрузкой списка',
          text: e.response._data.message,
          variant: 'error'
        })
      }
    }
  }

  async function addTab(tab: IOrderTab) {
    const res = await addOrderTab({ object: tab.object, name: tab.name })

    if (!res.error) {
      setTabs(res)
    }
    // else $vzToast.show?
    return res
  }

  async function updateTabs(value?: IOrderTab[]) {
    const list = (value || tabs.value).filter(t => t.tabCode || t.tabId)
    const res = await sortOrderTabs({ list })
    setTabs(res)
    return res
  }

  async function updateTab(tab: Partial<IOrderTab>) {
    const findTab = tabByCode(tab.tabCode || tab.tabId)
    const res = await updateOrderTab({ ...findTab, ...tab })
    setTabs(res)
    return res
  }

  async function removeTab(tabId: IOrderTab['tabId']) {
    const res = await removeOrderTab({ tabId })
    setTabs(res)
    return res
  }

  async function openDocument(requestData: IOrderDocumentRequest): Promise<void> {
    try {
      const { documentUrl, error } = await getOrderDocument(requestData)
      if (documentUrl) {
        window.open(documentUrl, '_blank')
      } else {
        $showToast({
          title: error?.message || 'Файл не найден',
          variant: 'error'
        })
      }
    } catch (e: any) {
      $showToast({
        title: e.response?._data?.error?.message || 'Файл не найден',
        variant: 'error'
      })
    }
  }

  async function orderSendBill(requestData: IOrderSendBillRequest) {
    modalLoader.value = true
    const { message, error } = await orderSendBillToEmail(requestData)
    modalLoader.value = false
    $showToast({
      title: 'Счёт на почту',
      text: error?.opt.message.message || message,
      variant: message ? 'success' : 'error'
    })
    if (message) {
      $hideDialog({name: 'email-bill-modal'})
    }
  }

  function updateListItem(data: { code: OrderTabName, key: string|number, value: any, keyName?: string }): void {
    const findTab = tabByCode(data.code)
    const findIndex = findTab.list?.findIndex((item: any) => item[data.keyName || 'id'] === data.key)

    if (!~findIndex) {
      return setTab({
        code: data.code,
        tab: findTab
      })
    }
    findTab.list[findIndex] = data.value

    return setTab({
      code: data.code,
      tab: findTab
    })
  }

  // При инициализации
  getFavors().then()
  getDraftFavors().then()
  getStatuses().then()

  watch(isAuth, (value, oldValue) => {
    if (process.client && value && !oldValue) {
      getTabs().then()
      getOrderListCardSettings().then()
    }
  }, { immediate: true, flush: 'post' })

  return {
    statuses,
    visibleStatuses,
    favors,
    draftFavors,
    tabs,
    lastCurrentTab,
    shippingDiagram,
    allTabs,
    activeTabs,
    defaultTab,
    badges,
    orderListCardSettings,
    orderListCardSettingsView,

    statusByCode, favorByCode, tabByCode, tabKey, indexByCode,
    setTabs, setTab, addTab,
    updateTabs, updateTab, removeTab, updateListItem,
    orderSendBill, openDocument
  }
})
